import moment, { DurationInputArg1, DurationInputArg2 } from 'moment';
import { notification } from 'antd';
import { LBNumFields, SEPARATORS, TOUR_TYPES } from '../common/constants';
import copy from 'copy-to-clipboard';
import { GetStateInterface } from '../types/GetStateInterface';
import { errorExtractor } from './error.utils';
import { TourMedia } from '../state/tour/tour.types';
import { cloneDeep, compact, orderBy, remove, trim } from 'lodash';
import { hexToRgb } from '../common/styled';

export const getHumanizeTime = (e: string): string => {
  return `${moment.duration(new Date(e).getTime() - new Date().getTime()).humanize()} ago`;
};

export const getProtectedEmail = (e: string): string => {
  if (!e.length) return e;
  const parts = e.split('@');
  const moreParts = parts[1] ? parts[1].split('.') : [''];
  let name = parts[0];
  let type = moreParts[0];
  if (name.length)
    name = name.slice(0, 1) + name.slice(1, name.length).replace(/[0-9a-zA-Z.]/g, '*');
  if (type.length)
    type = type.slice(0, 1) + type.slice(1, type.length).replace(/[a-z0-9A-Z.]/g, '*');
  const remain = moreParts.slice(1, moreParts.length).join('.');
  let final = name;
  if (type.length) final += '@' + type;
  if (remain.length) final += '.' + remain;
  return final;
};

export const showNotification = (
  type: 'error' | 'success' | 'info' | 'warn' | 'warning',
  message: string,
  description: string,
  additionalConfig: any = {},
) => {
  const obj = { ...additionalConfig, message, description };
  switch (type) {
    case 'error':
      notification.error(obj);
      break;

    case 'success':
      notification.success(obj);
      break;

    case 'info':
      notification.info(obj);
      break;

    case 'warn':
      notification.warn(obj);
      break;

    case 'warning':
      notification.warning(obj);
      break;

    default:
      break;
  }
};

export const noToAlpha = (num: number) => {
  let s = '',
    t;

  while (num > 0) {
    t = (num - 1) % 26;
    s = String.fromCharCode(65 + t) + s;
    num = ((num - t) / 26) | 0;
  }
  return s || undefined;
};

export const errorToString = (e: any): string => {
  let output = 'Please fix the following issues first (';
  const res = [];
  for (const i in e) {
    if (e[i].length) res.push(i);
  }
  if (res.length) {
    output += res.join(SEPARATORS.COMMA + SEPARATORS.SPACE) + ')';
    return output;
  } else return '';
};

export const injectScriptIntoHead = (
  src?: string,
  async?: boolean,
  text?: string,
  type?: string,
) => {
  const script = document.createElement('script');
  if (src) script.src = src;
  if (async) script.async = true;
  if (text) script.text = text;
  if (type) script.type = type;
  document.head.appendChild(script);
  return () => {
    document.head.removeChild(script);
  };
};

export const compileGoogleMapsScriptSrc = (key: string) => {
  return `https://maps.googleapis.com/maps/api/js?key=${key}&v=3.exp&libraries=geometry,drawing,places`;
};

export const getDomainFromSubdomain = (path: string) => {
  const host = window.location.host.split('.');
  host?.shift();
  const newHost = host?.join('.');
  return `${window.location.protocol}//${newHost + path}`;
};

export const validateUrl = (e: string) => {
  try {
    const url = new URL(e);
    if (url) {
      const pattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
          '(\\#[-a-z\\d_]*)?$',
        'i',
      ); // fragment locator
      return !!pattern.test(e);
    }
  } catch (err) {
    return false;
  }
};

export const generateRandomString = (length: number): string => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const silverMapDesign = [
  {
    elementType: 'geometry',
    stylers: [{ color: '#f5f5f5' }],
  },
  {
    elementType: 'labels.text.fill',
    stylers: [{ color: '#616161' }],
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [{ color: '#f5f5f5' }],
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#bdbdbd' }],
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [{ color: '#eeeeee' }],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#757575' }],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [{ color: '#e5e5e5' }],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#9e9e9e' }],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [{ color: '#ffffff' }],
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#757575' }],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [{ color: '#dadada' }],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#616161' }],
  },
  {
    featureType: 'road.local',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#9e9e9e' }],
  },
  {
    featureType: 'transit.line',
    elementType: 'geometry',
    stylers: [{ color: '#e5e5e5' }],
  },
  {
    featureType: 'transit.station',
    elementType: 'geometry',
    stylers: [{ color: '#eeeeee' }],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [{ color: '#c9c9c9' }],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#9e9e9e' }],
  },
];

export const getInitials = (name: string) => {
  const initials = name ? name?.match(/\b\w/g) || [] : [];
  return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
};

export const getMainDomain = () => {
  const host = window.location.host?.split(SEPARATORS.DOT);
  if (host?.length && ['app', 'www'].includes(host[0])) {
    host.shift();
  }
  return host.join(SEPARATORS.DOT);
};

export const getTourLink = (
  tourId: string,
  expiredAt: number,
  domain: string,
  tourUrl?: string,
) => {
  const newHost = getMainDomain();
  const link = tourUrl || `${window.location.protocol}//${domain}.${newHost}/tour/${tourId}`;
  const expiryInMinutes = Math.floor(
    moment
      .duration(moment.unix(expiredAt).diff(moment.unix(moment(Date.now()).unix())))
      .asMinutes(),
  );
  let expiry = expiryInMinutes;
  let format = expiryInMinutes > 1 ? 'minutes' : 'minute';
  if (expiryInMinutes > 1439) {
    expiry = Math.floor(expiryInMinutes / 1440);
    format = expiry > 1 ? 'days' : 'day';
  } else if (expiryInMinutes > 59) {
    expiry = Math.floor(expiryInMinutes / 60);
    format = expiry > 1 ? 'hours' : 'hour';
  }
  return { link, expiry, format };
};

export const copyTourLinkToClipboard = (
  tourId: string,
  expiredAt: number,
  type: TOUR_TYPES,
  subdomain?: string,
  tourUrl?: string,
) => {
  if (subdomain) {
    const { link, format, expiry } = getTourLink(tourId, expiredAt, subdomain, tourUrl);
    copy(link);
    const expiryMessage =
      type === TOUR_TYPES.LIVE ? `This link will expire in ${expiry} ${format}` : '';
    showNotification('success', 'Link copied to the clipboard', expiryMessage);
    document.body.click();
  } else {
    showNotification('info', 'Information', 'Please wait. Request in progress, try in few minutes');
  }
};

export const checkIfNumber = (val: any): boolean => {
  if (!val) return false;
  else return !isNaN(val) && isFinite(val);
};

export const toTitleCase = (val: any): string => {
  const str = val.toString();
  return str.replace(/\w\S*/g, function(txt: string) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const assignValToSpec = (
  field: string,
  obj: any,
  value: number | string | null,
  data?: any,
): any => {
  const dataField = data[field];
  if (obj[field]) obj[field].value = value;
  else obj[field] = { value, visibility: data[field] ? dataField.visibility : true };
  return obj;
};

export const updateVisibility = (field: string, obj: any, visible: boolean, data?: any): any => {
  if (!data[field] && !obj[field]) obj[field] = { value: null, visibility: visible };
  else {
    const dataField = data[field];
    if (!obj[field]) obj[field] = dataField;
    if (obj[field].value === undefined) obj[field].value = null;
    obj[field].visibility = visible;
  }
  return obj;
};

export const moveImagePanel = (dir: number, imagePanelRef: any, name: string, oneImage = 220) => {
  if (imagePanelRef.current) {
    const left = Math.ceil(imagePanelRef.current.scrollLeft);
    const images = document.getElementsByClassName(name);
    const imageWidth = images && images?.length ? images[0].scrollWidth : oneImage;
    let moveSpan = imageWidth;
    if (dir === 1 && left % imageWidth !== 0) moveSpan = imageWidth - (left % imageWidth);
    if (dir === -1 && left % imageWidth !== 0) moveSpan = left % imageWidth;
    if (imagePanelRef.current.scrollTo) {
      imagePanelRef.current.scrollTo(left + dir * moveSpan, 0);
    } else {
      imagePanelRef.current.scrollLeft = left + dir * moveSpan;
    }
  }
};

export const getLBInterface = (key: string, value: string | null): string | number | null => {
  return LBNumFields.includes(key) && value ? parseFloat(value) : value;
};

export const mergeWithAnd = (e: string[]): string => {
  if (e.length <= 1) return e.join('');
  const lastOne = e.pop();
  return e.join(SEPARATORS.COMMA + SEPARATORS.SPACE) + ' and ' + lastOne;
};

export const numToString = (e: number): string => {
  return e < 10 ? `0${e}` : e.toString();
};

export const convertSecToString = (e: number, showMilli?: boolean): string => {
  let time = Math.floor(e);
  const milli = parseInt(((e - time) * 100).toString(), 10);
  const min = Math.floor(time / 60);
  time -= min * 60;
  let output = '';
  output += `${numToString(min)}:${numToString(time)}`;
  if (showMilli) output += `:${numToString(milli)}`;
  return output;
};

export const getFileExtension = (e: string): string => {
  const typeParts = e.split(SEPARATORS.SLASH);
  let type = typeParts[0];
  if (typeParts.length > 1) type = typeParts[typeParts.length - 1];
  return type;
};

export const responseExtractor = (
  response: GetStateInterface,
  showSuccessMessage?: string,
): any => {
  if (!response.loading && response.hasData) {
    if (response.error) {
      showNotification('error', 'Response Error', errorExtractor(response.error));
      return null;
    } else if (response.data) {
      if (showSuccessMessage) showNotification('success', 'Success', showSuccessMessage);
      return response.data;
    }
  }
  return null;
};

const getTourName = (index: number, isTour?: boolean): string => {
  return `${isTour ? 'Tour' : 'Amenity'} ${index}`;
};

const getTourSmallName = (index: number, isTour?: boolean): string => {
  return `${isTour ? 'T' : 'A'}${index}`;
};

export const getSortedTours = (
  tours: Array<{ name: string; url: string; isPrimary?: boolean }>,
  isPrimarySorting?: boolean,
  isTour?: boolean,
): Array<TourMedia> => {
  if (!tours?.length) {
    return [];
  }
  const clonedTours = cloneDeep(tours);
  let primaryTour = ([] as unknown) as TourMedia[];
  if (isPrimarySorting) {
    primaryTour = remove(clonedTours, tour => tour.isPrimary);
  }
  const unNamedTours = remove(clonedTours, tour => !trim(tour.name));
  const trimmedTours = clonedTours.map(tour => ({
    ...tour,
    name: trim(tour.name),
  }));
  const orderedTours = orderBy(trimmedTours, ['name'], ['asc']);
  const namedOrderedTours = orderedTours.map(tour => ({
    ...tour,
    smallName: getInitials(tour.name),
  }));
  let primary: TourMedia | undefined = undefined;
  if (isPrimarySorting) {
    primary = primaryTour.length ? primaryTour[0] : undefined;
    if (primary) {
      primary = {
        ...primary,
        name: trim(primary.name) || 'Main Tour',
        smallName: getInitials(trim(primary.name)) || 'MT',
      };
    }
  }
  const indexAddition = orderedTours.length + (primary ? 1 : 0) + 1;
  const newNamedTours: TourMedia[] = unNamedTours.map((tour, index) => ({
    ...tour,
    name: getTourName(index + indexAddition, isTour),
    smallName: getTourSmallName(index + indexAddition, isTour),
  }));
  return compact<TourMedia>([primary, ...namedOrderedTours, ...newNamedTours]);
};

export const getQueryParam = (e: any): string => {
  const query = decodeURIComponent(
    Array.isArray(e) && e?.length ? e[0] : typeof e === 'string' ? e : '',
  );
  return query;
};

export const formatTime = (seconds?: number, minutes?: number, hours?: number, days?: number) => {
  let formattedTime = '';
  if (days) {
    formattedTime += days + 'd';
  }
  if (hours) {
    formattedTime += hours + 'h';
  }
  if (minutes) {
    formattedTime += minutes + 'm';
  }
  if (seconds) {
    formattedTime += seconds + 's';
  }
  return formattedTime;
};

export const getReadableTime = (
  time?: DurationInputArg1,
  unit: DurationInputArg2 = 'seconds',
): string | undefined => {
  const momentTime = moment.duration(time, unit);
  if (!time) {
    return undefined;
  } else if (time < 60) {
    return formatTime(momentTime.seconds());
  } else if (time < 3600) {
    return formatTime(momentTime.seconds(), momentTime.minutes());
  } else if (time < 86400) {
    return formatTime(momentTime.seconds(), momentTime.minutes(), momentTime.hours());
  } else {
    return formatTime(
      momentTime.seconds(),
      momentTime.minutes(),
      momentTime.hours(),
      momentTime.days(),
    );
  }
};

export const getPlayerTime = (
  time?: DurationInputArg1,
  unit: DurationInputArg2 = 'milliseconds',
) => {
  if (!time) return '00:00';
  const duration = moment.duration(time, unit);
  const years = duration.years();
  const months = duration.months();
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  let formattedTime = '';

  if (years) {
    formattedTime += (years < 10 ? `0${years}` : years) + ':';
  }
  if (months) {
    formattedTime += (months < 10 ? `0${months}` : months) + ':';
  }
  if (hours) {
    formattedTime += (hours < 10 ? `0${hours}` : hours) + ':';
  }
  formattedTime += (minutes < 10 ? `0${minutes}` : minutes) + ':';
  formattedTime += seconds < 10 ? `0${seconds}` : seconds;

  return formattedTime;
};

export const getContrast = (color: string, opacity: number) => {
  const rgb = hexToRgb(color);
  return {
    color: `${opacity > 0.5 ? '#fff' : '#1C1F20'}`,
    backgroundColor: `rgba(${rgb?.r},${rgb?.g},${rgb?.b},${opacity}`,
  };
};

export const getCentsToDollars = (num: number) => {
  return Number((num / 100).toFixed(2));
};
