import styled from 'styled-components';
import { lighten, darken } from 'polished';
import { Button, Nav, Dropdown, Form, Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ReactInputMask from 'react-input-mask';
import { DatePicker } from 'antd';
import { SEPARATORS } from './constants';
import { Pagination } from 'antd';
import QuillEditor from '../components/common/quillEditor';

interface Props {
  secondary?: boolean;
}

export const hexToRgb = (hex: string) => {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function(m, r, g, b) {
    return r + r + g + g + b + b;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

export const rgbToHex = (r: number, g: number, b: number) => {
  return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
};

const getLightOrDarkColor = (color: string, dark = true) => {
  const rgb = hexToRgb(color);
  if (rgb) {
    if (dark) {
      return darken(0.1, color);
    } else {
      return lighten(0.1, color);
    }
  } else {
    return dark ? '#000' : '#fff';
  }
};

const changeColor = (color: string) => {
  if (color) {
    return getLightOrDarkColor(color);
  } else return '';
};

const whiteColor = 'color: white !important;';
const hoverOptions = (changes?: string): string => {
  if (!changes) return `&:hover, &:focus, &:active, &.active, &:active:focus, &.active:focus`;
  else {
    const parts = changes.split(SEPARATORS.COMMA);
    if (parts[0].length) parts[0] = ` ${parts[0]}`;
    return `
      &${parts[0]}:hover ${parts[1]},
      &${parts[0]}:focus ${parts[1]},
      &${parts[0]}:active ${parts[1]},
      &${parts[0]}.active ${parts[1]},
      &${parts[0]}:active:focus ${parts[1]},
      &${parts[0]}.active:focus ${parts[1]}`;
  }
};
const getCss = (
  props: any,
  key: string,
  change?: boolean,
  secondary?: boolean,
  opacity?: number,
) => {
  let color = secondary ? props.theme?.secondary : props.theme?.primary;
  if (color) color = change ? changeColor(color) : color;
  if (opacity === 0 || opacity) {
    const rgb = hexToRgb(color);
    return `${key}: rgba(${rgb?.r},${rgb?.g},${rgb?.b},${opacity}) !important;`;
  } else {
    return `${key}: ${color || ''} !important;`;
  }
};
const getProperty = (
  props: any,
  key: string,
  className: string,
  negation?: boolean,
  change?: boolean,
): string => {
  let output = '';
  if (negation && !props.className?.includes(className)) output = getCss(props, key, change);
  else if (!negation && props.className?.includes(className)) output = getCss(props, key, change);
  return output;
};

export const StyledFilledButton = styled(Button)`
  ${props =>
    (!props.className.includes('no-bg') ? getCss(props, 'background-color') : '') +
    (!props.className.includes('no-border') && !props.className.includes('no-fix-border')
      ? getCss(props, 'border-color', false)
      : '')};

  ${hoverOptions()} {
    ${props =>
      (!props.className.includes('no-bg')
        ? getCss(props, 'background-color', true)
        : 'background: transparent !important;') +
      (!props.className.includes('no-border')
        ? getCss(props, 'border-color', true)
        : 'border-color: transparent !important;')}
  }
`;
export const StyledHollowButton = styled(Button)`
  ${props => (!props.unpublish ? getCss(props, 'border-color') + getCss(props, 'color') : '')};

  ${hoverOptions()} {
    ${props =>
      !props.unpublish
        ? getCss(props, 'border-color') + getCss(props, 'background-color') + whiteColor
        : ''};
  }
  & .listing__btn-icon,
  & .listing__btn-heading {
    ${props => (!props.unpublish ? getCss(props, 'color') : '')};
  }

  ${hoverOptions(',.listing__btn-icon')},
  ${hoverOptions(',.listing__btn-heading')} {
    ${whiteColor}
  }
`;
export const StyledHoverOnlyButton = styled(Button)`
  ${hoverOptions()},
  ${hoverOptions(',.link__icon')} {
    ${props =>
      getProperty(props, 'color', 'fill') +
      (props.className?.includes('hollow') ? whiteColor : '') +
      getProperty(props, 'background-color', 'hollow')}
  }
  &:disabled:not(:hover) {
    background: transparent !important;
    color: #bcbab8 !important;
  }
`;

export const StyledHoverAndActiveButton = styled(Button)`
  ${hoverOptions()} {
    ${props =>
      getProperty(props, 'color', 'colorHover') +
      getProperty(props, 'border-color', 'borderHover') +
      (props.className?.includes('hollow') ? whiteColor : '') +
      getProperty(props, 'background-color', 'hollow')}
  }
  ${props =>
    getProperty(props, 'color', 'fill') +
    getProperty(props, 'border-color', 'borderColor') +
    (props.className?.includes('hollow') ? whiteColor : '') +
    getProperty(props, 'background-color', 'hollow')}
  &:disabled:not(:hover) {
    background: transparent !important;
    color: #bcbab8 !important;
  }
`;

export const StyledSidebarLink = styled(Link)<Props>`
  ${hoverOptions()} {
    ${props => getCss(props, 'color', false, true)}
  }
`;
export const StyledSidebarButton = styled(Button)`
  ${hoverOptions()} {
    ${props => getCss(props, 'color', false, true)}
  }
`;
export const StyledSidebarStyledAnchor = styled.a<Props>`
  ${hoverOptions()} {
    ${props => getCss(props, 'color', false, true)}
  }
`;

export const StyledPlayButton = styled.a`
  ${props => getCss(props, 'border-color') + getCss(props, 'color')}

  ${hoverOptions()} {
    ${props => getCss(props, 'border-color') + getCss(props, 'background-color') + whiteColor}
  }
`;
export const StyledRightDrawer = styled.div`
  background: linear-gradient(
    89.65deg,
    ${props => props.theme.primary} 18.24%,
    ${props => getLightOrDarkColor(props.theme.primary, false)} 91.95%
  );
`;
export const StyledH6 = styled.h6`
  ${props => getProperty(props, 'color', 'color')}
`;
export const StyledH5 = styled.h5`
  ${props => getProperty(props, 'color', 'color')}
`;
export const StyledH4 = styled.h4`
  ${props => getCss(props, 'color')}
`;
export const StyledH3 = styled.h3`
  ${props => getCss(props, 'color')}
`;
export const StyledH2 = styled.h2`
  ${props => getCss(props, 'color')}
`;
export const StyledH1 = styled.h1<Props>`
  ${props =>
    props.className?.includes('secondary')
      ? getCss(props, 'color', false, true)
      : getCss(props, 'color')}
`;
export const StyledLabel = styled.label`
  ${hoverOptions()} {
    ${props => getCss(props, 'color', true)}
  }
  ${hoverOptions(',.checkmark')} {
    ${props => getCss(props, 'border-color')}
  }
  & .checkmark:after {
    ${props => getCss(props, 'background-color')}
  }
  ${props => getProperty(props, 'color', 'fixColor')}
`;
export const StyledTick = styled.span`
  input:checked ~ & {
    ${props => getCss(props, 'background-color') + getCss(props, 'border-color')}
  }
`;
export const StyledFooterOptions = styled.a`
  ${props => getCss(props, 'color', false, true)}

  ${hoverOptions()} {
    ${props => getCss(props, 'color', true, true)}
  }
`;
export const StyledNavLink = styled(Nav.Link)`
  ${props =>
    getProperty(props, 'background-color', 'filled') +
    (props.className?.includes('filled') ? whiteColor : '')}
  ${hoverOptions()},
  ${hoverOptions(',.tb__link-icon')} {
    ${props => getProperty(props, 'color', 'hover')}
  }
  ${hoverOptions()} {
    ${props => getProperty(props, 'border-bottom-color', 'bg-bottom')}
  }
`;
export const StyledSpan = styled.span`
  ${hoverOptions()} {
    ${props => getProperty(props, 'color', 'hover')}
  }
  ${props => getProperty(props, 'color', 'fill') + getProperty(props, 'background-color', 'bg')}
`;
export const StyledFormControl = styled(Form.Control)`
  ${hoverOptions()} {
    ${props => getCss(props, 'border-color')}
  }
`;
export const StyledDiv = styled.div`
  & .fill-button {
    ${props => getCss(props, 'background-color') + getCss(props, 'border-color')}
  }
  & .fill-text {
    ${props => getCss(props, 'color')}
  }
  ${hoverOptions()} {
    ${props =>
      getProperty(props, 'border-color', 'hoverBorder') + getProperty(props, 'color', 'hoverColor')}
  }
  ${hoverOptions(',.fill-button')} {
    ${props => getCss(props, 'background-color', true) + getCss(props, 'border-color', true)}
  }
  ${hoverOptions(',.fill-text')} {
    ${props => getCss(props, 'color', true)}
  }
  ${props => getProperty(props, 'border-color', 'borderColor')}
`;
export const StyledAnchor = styled.a`
  ${props =>
    getProperty(props, 'color', 'plain') +
    getProperty(props, 'color', 'hollow') +
    getProperty(props, 'border-color', 'hollow') +
    (props.className?.includes('filled') ? whiteColor : '') +
    getProperty(props, 'border-color', 'filled') +
    getProperty(props, 'background-color', 'filled')}

    ${hoverOptions()} {
    ${props =>
      getProperty(props, 'color', 'hover') +
      (props.className?.includes('hollow') ? whiteColor : '') +
      getProperty(props, 'background-color', 'hollow') +
      getProperty(props, 'border-color', 'filled', undefined, true) +
      getProperty(props, 'background-color', 'filled', undefined, true)}
`;

export const StyledLink = styled(Link)`
  ${props =>
    getProperty(props, 'color', 'plain') +
    getProperty(props, 'color', 'hollow') +
    getProperty(props, 'border-color', 'hollow') +
    getProperty(props, 'border-color', 'filled') +
    getProperty(props, 'background-color', 'filled')}

  ${hoverOptions()} {
    ${props =>
      (props.className?.includes('hollow') ? whiteColor : '') +
      getProperty(props, 'background-color', 'hollow') +
      getProperty(props, 'border-color', 'filled', undefined, true) +
      getProperty(props, 'background-color', 'filled', undefined, true)}
  }
`;
export const StyledDatePicker = styled(DatePicker)`
  ${hoverOptions(',div input')} {
    ${props => getCss(props, 'border-color')}
  }
`;
export const StyledInputMask = styled(ReactInputMask)`
  ${hoverOptions()} {
    ${props => getCss(props, 'border-color')}
  }
`;
export const StyledDropdown = styled(Dropdown)`
  ${hoverOptions('> .dropdown-toggle,')},
  &.show > .dropdown-toggle {
    ${props => getCss(props, 'border-color')}
  }
  & .dropdown-menu {
    ${props => getCss(props, 'border-color')}
    ${hoverOptions('.dropdown-item,')} {
      ${props => getCss(props, 'background-color')}
    }
  }
`;

export const StyledPagination = styled(Pagination)`
  & li:not(.ant-pagination-disabled),
  & li:not(.ant-pagination-disabled) {
    ${hoverOptions()} {
      ${props => getCss(props, 'border-color')}
    }
  }
  & li:not(.ant-pagination-disabled) {
    ${hoverOptions()} {
      & a,
      & button {
        ${props => getCss(props, 'color')}
        ${props => getCss(props, 'border-color')}
      }
    }
  }
  & li.ant-pagination-item-active {
    ${props => getCss(props, 'border-color')}
  }
  & li.ant-pagination-item-active a,
  & li a span.ant-pagination-item-link-icon {
    ${props => getCss(props, 'color')}
  }
  & li button.ant-pagination-item-link svg {
    vertical-align: initial;
  }
  .template--mobile & {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    &:after {
      content: none;
    }
    & li:first-child {
      flex: 0 0 100%;
      max-width: 100%;
      margin: 0px 0px 12px 0px;
      text-align: center;
    }
  }
  @media (max-width: 575px) {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    &:after {
      content: none;
    }
    & li:first-child {
      flex: 0 0 100%;
      max-width: 100%;
      margin: 0px 0px 12px 0px;
      text-align: center;
    }
  }
`;

export const StyledVideoSessionPlayback = styled.div`
  & .rr-controller__btns.svelte-dxnc1j button.svelte-dxnc1j svg {
    ${props => `fill: ${props.theme.primary} !important;`}
  }
  & .rr-controller__btns.svelte-dxnc1j button.svelte-dxnc1j {
    ${props => getCss(props, 'color')}
  }

  & .rr-controller__btns.svelte-dxnc1j button.active.svelte-dxnc1j,
  & .rr-progress__step.svelte-dxnc1j.svelte-dxnc1j,
  & .switch input[type='checkbox']:checked + label.svelte-1mmdovf.svelte-1mmdovf:before {
    ${props => whiteColor + getCss(props, 'background')}
  }

  & .rr-progress__step.svelte-dxnc1j.svelte-dxnc1j {
    border-radius: 20px;
  }

  & .switch.svelte-1mmdovf label.svelte-1mmdovf:before {
    ${props => getCss(props, 'background', false, false, 0.5)}
  }
`;
export const StyledCarousel = styled(Carousel)`
  & .carousel-indicators li.active {
    ${props => getCss(props, 'background-color') + getCss(props, 'border-color')}
  }
`;

export const StyledQuillEditor = styled(QuillEditor)``;
