import React, { lazy, useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
// load css
import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/lib/notification/style/css';
import 'antd/lib/message/style/css';
import 'antd/lib/date-picker/style/css';
import 'antd/lib/pagination/style/css';
import './assets/css/icon.css';
import './assets/css/style.css';
import './assets/css/responsive.css';
import './assets/css/shimmer.css';
import './assets/css/custom.css';

import { useGetCompanyInfo } from './state/subdomain/company/company.hook';
import Loader from './components/common/loader';
import { ALLOWED_SUBDOMAINS_MAIN_APP, NODE_ENV } from './common/constants';
import { compileAvatarUrl } from './utils/avatar.utils';
import env from './utils/env.utils';
import FullStory from 'react-fullstory';
import Intercom from 'react-intercom';
import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga';
import { errorCodeExtractor, errorExtractor } from './utils/error.utils';
import withErrorBoundary from './components/hoc/withErrorBoundary';

const MainApp = lazy(() => import('./pages/app'));
const SubdomainApp = lazy(() => import('./pages/subdomain/app'));
const NotFound = lazy(() => import('./pages/error/404'));
const NetworkError = lazy(() => import('./pages/error/network'));
const InternalServerError = lazy(() => import('./pages/error/500'));

const App: React.FC = () => {
  const [subdomain] = useState(window.location.hostname.split('.')[0]);
  const { res: getCompanyInfoResponse, getCompanyInfo } = useGetCompanyInfo();

  useEffect(() => {
    if (env.nodeEnv === NODE_ENV.PRODUCTION) {
      ReactGA.initialize(env.googleAnalyticsTrackingId);
      TagManager.initialize({
        gtmId: env.googleTagManagerId,
      });
    }
  }, []);

  useEffect(() => {
    if (!ALLOWED_SUBDOMAINS_MAIN_APP.includes(subdomain)) {
      getCompanyInfo(subdomain);
    }
  }, [subdomain]);

  useEffect(() => {
    if (!getCompanyInfoResponse.loading && getCompanyInfoResponse.data) {
      if (getCompanyInfoResponse.data.name) {
        document.title = getCompanyInfoResponse.data.name;
      }
      if (getCompanyInfoResponse.data.settings?.darkIcon) {
        const link: any =
          document.querySelector("link[rel*='icon']") || document.createElement('link');
        link.rel = 'shortcut icon';
        link.href = compileAvatarUrl(getCompanyInfoResponse.data.settings.darkIcon.url);
        document.getElementsByTagName('head')[0].appendChild(link);
      }
    }
  }, [getCompanyInfoResponse]);

  return (
    <React.Fragment>
      <React.Suspense fallback={<Loader />}>
        {ALLOWED_SUBDOMAINS_MAIN_APP.includes(subdomain) ? (
          <Router>
            <MainApp />
          </Router>
        ) : (
          <>
            {getCompanyInfoResponse.loading ? (
              <Loader />
            ) : (
              <>
                {getCompanyInfoResponse.data ? (
                  <Router>
                    <SubdomainApp />
                  </Router>
                ) : (
                  <>
                    {errorCodeExtractor(getCompanyInfoResponse.error) === 404 ? (
                      <NotFound />
                    ) : (
                      <>
                        {errorExtractor(getCompanyInfoResponse.error) === 'Network Error' ? (
                          <NetworkError />
                        ) : (
                          <InternalServerError />
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
        {env.nodeEnv === NODE_ENV.PRODUCTION && (
          <>
            <FullStory org={env.fullStoryOrgId} />
            <Intercom appID={env.intercomAppId} />
          </>
        )}
      </React.Suspense>
    </React.Fragment>
  );
};

export default withErrorBoundary(App);
