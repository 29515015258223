import React, { forwardRef } from 'react';
import { useMediaSize } from '../../state/media/media.hook';
import { compileAvatarUrl } from '../../utils/avatar.utils';
import { DEFAULT_MAX_IMG_HEIGHT, DEFAULT_MAX_IMG_WIDTH } from '../../common/constants';

interface Props {
  src: string;
  alt?: string;
  className?: string;
  default?: string;
  imgWidth?: number;
  imgHeight?: number;
}
const ImgTile = forwardRef<Props, any>((props, ref) => {
  const { src, alt, className, default: defaultImg, imgWidth, imgHeight } = props;
  const { reference, width: calculatedWidth, height: calculatedHeight } = useMediaSize();

  const width = imgWidth || calculatedWidth;
  const height = imgHeight || calculatedHeight;
  return (
    <React.Fragment>
      {src !== undefined && (
        <img
          src={
            src && width && height
              ? compileAvatarUrl(src, { width, height })
              : src
              ? compileAvatarUrl(src, {
                  width: Math.min(DEFAULT_MAX_IMG_WIDTH, window.innerWidth),
                  height: Math.min(DEFAULT_MAX_IMG_HEIGHT, window.innerHeight),
                })
              : defaultImg || ''
          }
          ref={ref || reference}
          alt={alt || 'image'}
          className={className || ''}
        />
      )}
    </React.Fragment>
  );
});

export default ImgTile;
