import { UploadedMediaInterface } from '../media/media.types';
import { GetStateInterface } from '../../types/GetStateInterface';
import { AmenityMatterPortLink, SingleBuildingDataInterface } from '../building/building.types';
import { LISTING_STATUS, REQUEST_MEDIA_TYPES } from '../../common/constants';

export interface ListingStateInterface {
  allListings: GetStateInterface<GetAllListingsResponseInterface>;
  singleListing: GetStateInterface<SingleListingDataInterface>;
}

export enum ListingActions {
  POST_LISTING = 'POST_LISTING',
  POST_REQUEST_MEDIA = 'POST_REQUEST_MEDIA',
  UPDATE_LISTING = 'UPDATE_LISTING',
  LISTING_STATUS = 'LISTING_STATUS',
  VIDEO_STATUS = 'VIDEO_STATUS',
  LISTING_UPDATE = 'LISTING_UPDATE',
  VIDEO_UPDATE = 'VIDEO_UPDATE',
  GET_TOUR_URL = 'GET_TOUR_URL',
  DELETE_LISTING = 'DELETE_LISTING',
  GET_ALL_RECENT_LISTINGS = 'GET_ALL_RECENT_LISTINGS',
  EXPORT_LISTINGS = 'EXPORT_LISTINGS',
  GET_LISTINGS = 'GET_LISTINGS',
  GET_BUILDING_LISTINGS = 'GET_BUILDING_LISTINGS',
  GET_ALLOWED_TO_PUBLISH_LISTING = 'GET_ALLOWED_TO_PUBLISH_LISTING',
  GET_ALL_LISTINGS = 'GET_ALL_LISTINGS',
  GET_ALL_LISTINGS_INIT = 'GET_ALL_LISTINGS_INIT',
  GET_ALL_LISTINGS_CLEAR = 'GET_ALL_LISTINGS_CLEAR',
  GET_ALL_LISTINGS_FAILURE = 'GET_ALL_LISTINGS_FAILURE',
  GET_ALL_LISTINGS_SUCCESSFUL = 'GET_ALL_LISTINGS_SUCCESSFUL',

  GET_SINGLE_LISTING = 'GET_SINGLE_LISTING',
  GET_SINGLE_LISTING_INIT = 'GET_SINGLE_LISTING_INIT',
  GET_SINGLE_LISTING_CLEAR = 'GET_SINGLE_LISTING_CLEAR',
  GET_SINGLE_LISTING_FAILURE = 'GET_SINGLE_LISTING_FAILURE',
  GET_SINGLE_LISTING_SUCCESSFUL = 'GET_SINGLE_LISTING_SUCCESSFUL',
}

export interface PhotoInterface extends UploadedMediaInterface {
  isPrimary: boolean;
}
export interface ListingInterface {
  spaceUse: string;
  suiteNo: string;
  size: number;
  monthlyRent: number | null;
  capacity: string;
  condition: string;
  metroProximity: string;
  description: string;
  matterPortLinks: PhotoInterface[];
  videoDescription: string;

  available: string;
  media: PhotoInterface[];
  floorPlans: UploadedMediaInterface[];
  pdfs: UploadedMediaInterface[];
  users: string[];

  building: string;
}

export interface User {
  _id: string;
  avatar: string;
  firstName: string;
  lastName: string;
  phone: string;
  role: string;
  email: string;
}
export interface Broker extends User {
  company: Company;
}

interface Company {
  _id: string;
  name: string;
}

export interface AudioAndCommentUser {
  _id: string;
  avatar: string;
  firstName: string;
  lastName: string;
}

export interface SubContentInterface {
  text?: string;
  media?: UploadedMediaInterface;
}

export interface CommentsInterface {
  _id?: string;
  videoTimestamp?: string;
  audioTimestamp?: string;
  audioVoiceOverId?: string;
  content: SubContentInterface;
  commentBy?: AudioAndCommentUser;
  isActive?: boolean;
  isEdited?: boolean;
  lastEditedBy?: AudioAndCommentUser;
  createdAt?: string;
  updatedAt?: string;
  isPrevious?: boolean;
}

export interface AudioVoiceOverInterface {
  _id?: string;
  segment: number;
  name: string;
  url: string;
  playVideoStartTimestamp: string;
  isActive?: boolean;
  voiceOverBy: AudioAndCommentUser;
  createdAt?: string;
  updatedAt?: string;
  isPrevious?: boolean;
}

export interface GetListingObject {
  _id: string;
  videoUrls?: VideoUrls[];
  videoStatus: string;
  isActive: boolean;
  status: string;
  users: Broker[];
  createdBy: CreatedBy;
  available: string;
  media: PhotoInterface[];
  videoDescription: string;
  description: string;
  metroProximity: string;
  monthlyRent: number;
  size: number;
  suiteNo: string;
  spaceUse: string;
  capacity: string;
  condition: string;
  building: SingleBuildingDataInterface;
  pdfs: UploadedMediaInterface[];
  floorPlans: UploadedMediaInterface[];
  renderings: UploadedMediaInterface[];
  audioVoiceOver: AudioVoiceOverInterface[];
  voiceOverComments: AudioAndCommentUser[];
  createdAt: string;
  updatedAt: string;
  matterPortLinks: PhotoInterface[];
  availableVisibility: boolean;
  capacityVisibility: boolean;
  conditionVisibility: boolean;
  descriptionVisibility: boolean;
  floorPlansVisibility: boolean;
  metroProximityVisibility: boolean;
  monthlyRentVisibility: boolean;
  sizeVisibility: boolean;
  spaceUseVisibility: boolean;
  suiteNoVisibility: boolean;
}

export enum SingleListingDetails {
  CAP = 'capacity',
  RENT = 'monthlyRent',
  SIZE = 'size',
  COND = 'condition',
  METRO = 'metroProximity',
  SUITE = 'suiteNo',
  AVAIL = 'available',
}
export enum SingleListingDetailVisibility {
  AVAIL = 'availableVisibility',
  CAP = 'capacityVisibility',
  COND = 'conditionVisibility',
  METRO = 'metroProximityVisibility',
  RENT = 'monthlyRentVisibility',
  SIZE = 'sizeVisibility',
  SUITE = 'suiteNoVisibility',
}
export interface SingleListingDataInterface {
  _id: string;
  videoStatus: string;
  isActive: boolean;
  status: string;
  users: Broker[];
  createdBy: CreatedBy;
  available: string;
  media: PhotoInterface[];
  videoDescription: string;
  description: string;
  metroProximity: string;
  monthlyRent: number;
  size: number;
  suiteNo: string;
  spaceUse: string;
  capacity: string;
  condition: string;
  building: BuildingInfo;
  pdfs: UploadedMediaInterface[];
  floorPlans: UploadedMediaInterface[];
  renderings: UploadedMediaInterface[];
  audioVoiceOver: AudioVoiceOverInterface[];
  voiceOverComments: AudioAndCommentUser[];
  createdAt: string;
  updatedAt: string;
  matterPortLinks: PhotoInterface[];
  availableVisibility: boolean;
  capacityVisibility: boolean;
  conditionVisibility: boolean;
  descriptionVisibility: boolean;
  floorPlansVisibility: boolean;
  metroProximityVisibility: boolean;
  monthlyRentVisibility: boolean;
  sizeVisibility: boolean;
  spaceUseVisibility: boolean;
  suiteNoVisibility: boolean;
  videoUrls: VideoUrls[];
}
interface BuildingInfo {
  _id: string;
  location: string;
  subMarket: any;
}

export interface VideoUrls extends PhotoInterface {
  uploadedByStaff: boolean;
}
export interface UpdateSingleListingDataInterface {
  videoStatus?: string;
  status?: string;
  users?: string[];
  available?: string;
  media?: PhotoInterface[];
  videoDescription?: string;
  description?: string;
  metroProximity?: string;
  monthlyRent?: number;
  size?: number;
  suiteNo?: string;
  spaceUse?: string;
  capacity?: string;
  condition?: string;
  pdfs?: UploadedMediaInterface[];
  floorPlans?: UploadedMediaInterface[];
  audioVoiceOver?: AudioVoiceOverInterface[];
  voiceOverComments?: AudioAndCommentUser[];
  matterPortLinks?: PhotoInterface[];
  availableVisibility?: boolean;
  capacityVisibility?: boolean;
  conditionVisibility?: boolean;
  descriptionVisibility?: boolean;
  floorPlansVisibility?: boolean;
  metroProximityVisibility?: boolean;
  monthlyRentVisibility?: boolean;
  sizeVisibility?: boolean;
  spaceUseVisibility?: boolean;
  suiteNoVisibility?: boolean;
  videoUrls?: VideoUrls[];
}
interface CreatedBy {
  _id: string;
  avatar: string;
  firstName: string;
  lastName: string;
}

export interface ListingsSelectedUser {
  id: string;
  name: string;
}
export interface GetAllListings {
  status: string;
  _id: string;
  media: PhotoInterface[];
  capacity: string;
  capacityVisibility: boolean;
  monthlyRent: number;
  monthlyRentVisibility: boolean;
  size: number;
  spaceUse: string;
  sizeVisibility: boolean;
  suiteNo: string;
  building: BuildingLoc;
  subMarket: SubMarket;
}
interface BuildingLoc {
  location: string;
}

export interface GetAllListingsResponseInterface {
  data: GetAllListings[];
  skip: number;
  hasMore: boolean;
  count: number;
}

export interface Building {
  _id: string;
  location: string;
  subMarket: SubMarket;
  latitude?: string;
  longitude?: string;
  fullLocation?: any;
  yearBuilt?: number;
  yearRenovated?: number;
  floorsCount?: number;
  buildingSize?: number;
  averageFloor?: number;
  parkingRatio?: string;
  columnSpacingHeight?: number;
  columnSpacingWidth?: number;
  slabToSlabHeight?: string;
  passengerElevators?: string;
  freightElevators?: string;
  telecomProviders?: string;
  buildingHours?: string[];
  energyStarCertification?: string;
  leedCertification?: string;
  buildingAmenities?: string[];
  buildingAmenitiesMatterportLinks?: AmenityMatterPortLink[];
}

interface SubMarket {
  isActive: boolean;
  _id: string;
  name: string;
  market: string;
}

export interface GetTourUrlResponseInterface {
  _id: string;
  tourUrl: string;
  expiryInDays: number;
}

export interface AudioAndCommentUser {
  _id: string;
  avatar: string;
  firstName: string;
  lastName: string;
}
export interface SubContentInterface {
  text?: string;
  media?: UploadedMediaInterface;
}
export interface CommentsInterface {
  _id?: string;
  videoTimestamp?: string;
  audioTimestamp?: string;
  audioVoiceOverId?: string;
  content: SubContentInterface;
  commentBy?: AudioAndCommentUser;
  isActive?: boolean;
  isEdited?: boolean;
  lastEditedBy?: AudioAndCommentUser;
  createdAt?: string;
  updatedAt?: string;
}
export interface AudioVoiceOverInterface {
  _id?: string;
  segment: number;
  name: string;
  url: string;
  playVideoStartTimestamp: string;
  isActive?: boolean;
  voiceOverBy: AudioAndCommentUser;
  createdAt?: string;
  updatedAt?: string;
}

export interface HandlerConditions {
  publish: boolean;
  unpublish: boolean;
  review: boolean;
}

export type RecentSortBy =
  | 'updatedAt'
  | 'monthlyRent'
  | 'size'
  | 'availableNow'
  | 'availableInNinety'
  | 'createdAt';

export type RecentOrderBy = 'ascending' | 'descending';

export type RecentSortAndOrder = {
  sortBy?: RecentSortBy;
  orderBy?: RecentOrderBy;
};

export interface GetRecentListingsQueryInterface extends RecentSortAndOrder {
  subMarket?: string;
  search?: string;
  limit?: number;
  skip?: number;
  status?: LISTING_STATUS;
}

export interface RecentListingBuildingInterface {
  _id: string;
  location: string;
}

export interface RecentListingSubMarketInterface {
  name: string;
  market: string;
}

export interface RecentListing {
  _id: string;
  status: string;
  media: PhotoInterface[];
  monthlyRent: number;
  size: number;
  suiteNo: string;
  capacity: string;
  building: RecentListingBuildingInterface;
  subMarket: RecentListingSubMarketInterface;
}

export interface GetRecentListingsResponseInterface {
  hasMore: boolean;
  skip: number;
  data: Array<RecentListing>;
}

export interface RequestMediaFieldsInterface {
  listingId: string;
  type: REQUEST_MEDIA_TYPES;
  url?: string;
  instruction?: string;
  attachment?: string[];
}

export interface RequestMediaInterface extends RequestMediaFieldsInterface {
  listingId: string;
}

export interface Attachment extends UploadedMediaInterface {
  index: number;
}

export interface SetValueOptions {
  shouldValidate?: boolean;
  shouldDirty?: boolean;
}

export interface GetListingResponse {
  _id: string;
  suiteNo: string;
  building: {
    location: string;
  };
}

export interface GetAllowedToPublishListingInterface {
  buildingId: string;
}
