import defaultAvatar from '../assets/images/user-default.svg';
import whiteLogo from '../assets/images/OfficeTour_White_Logo.svg';
import darkLogo from '../assets/images/OfficeTour_Dark_Logo.svg';
import whiteIcon from '../assets/images/OfficeTour_White_Icon.svg';
import darkIcon from '../assets/images/OfficeTour_Dark_Icon.svg';
import starCertificationImg from '../assets/images/star-certification.png';
import leedCertifiedImg from '../assets/images/Leed-Certified.png';
import leedPlatinumImg from '../assets/images/Leed-Platinum.png';
import leedGoldImg from '../assets/images/Leed-Gold.png';
import leedSilverImg from '../assets/images/Leed-Silver.png';
import pdfImg from '../assets/images/pdf.png';
import blackImg from '../assets/images/black.jpg';
import grayMapMarker from '../assets/images/map-marker-grey.svg';
import blueMapMarker from '../assets/images/map-marker-blue.svg';
import redMapMarker from '../assets/images/map-marker-red.svg';
import signInImg from '../assets/images/signin-img.svg';
import basicTemplateImg from '../assets/images/template-basic.png';
import classicTemplateImg from '../assets/images/template-classic.png';
import modernTemplateImg from '../assets/images/template-modern.png';
import chatUserImg from '../assets/images/chat-user.svg';
import changeBrowserImg from '../assets/images/change-browser.svg';
import browserDirectionImg from '../assets/images/browser-direction.svg';
import logoLandingImg from '../assets/images/logo-landing.svg';
import visaImg from '../assets/images/visa-sm.svg';
import masterCardImg from '../assets/images/master-card-sm.svg';
import amexImg from '../assets/images/amex-sm.svg';
import paymentCompletedImg from '../assets/images/payment--completed--bg.png';
import chatIcon from '../assets/images/chat-icon.svg';
import Tick from '../assets/images/tick.png';
import Visa from '../assets/images/Visa.png';
import Add from '../assets/images/add.svg';
import RoundedTick from '../assets/images/rounded-tick.svg';
const images = {
  Tick,
  Add,
  Visa,
  defaultAvatar,
  whiteLogo,
  darkLogo,
  whiteIcon,
  darkIcon,
  starCertificationImg,
  leedCertifiedImg,
  leedPlatinumImg,
  leedGoldImg,
  leedSilverImg,
  pdfImg,
  blackImg,
  grayMapMarker,
  blueMapMarker,
  redMapMarker,
  signInImg,
  basicTemplateImg,
  classicTemplateImg,
  modernTemplateImg,
  chatUserImg,
  changeBrowserImg,
  browserDirectionImg,
  logoLandingImg,
  visaImg,
  masterCardImg,
  amexImg,
  paymentCompletedImg,
  chatIcon,
  RoundedTick,
};

export const getLeedImage = (leedValue?: string) => {
  switch (leedValue) {
    case 'certified':
    case 'Certified':
      return leedCertifiedImg;
    case 'platinum':
    case 'Platinum':
      return leedPlatinumImg;
    case 'gold':
    case 'Gold':
      return leedGoldImg;
    case 'silver':
    case 'Silver':
      return leedSilverImg;
    default:
      return '';
  }
};

export default images;
