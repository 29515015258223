import React from 'react';
import { GetStateInterface } from '../../types/GetStateInterface';
import { PhotoInterface } from '../listing/listing.types';
import { UploadedMediaInterface } from '../media/media.types';
import { SubMarketInterface } from '../submarket/submarket.types';
import { BUILDING_TEMPLATE_STATUS, LISTING_STATUS } from '../../common/constants';

export interface AmenityMatterPortLink {
  name: string;
  url: string;
}
export interface AmenityMatterPortLinkWithError extends AmenityMatterPortLink {
  error: boolean;
}
export interface RemoveListingBrokersFromPlanInterface {
  buildingId: string;
  brokersIds: string[];
  listingsIds: string[];
}
interface FullLocation {
  selectedPlace: any;
  geoCodeAddress: any;
}

export interface BuildingValueInNumber {
  value: number;
  visibility: boolean;
}

export interface BuildingValueInString {
  value: string;
  visibility: boolean;
}
export interface BuildingDataPayloadInterface {
  location: string;
  latitude: BuildingValueInString;
  longitude: BuildingValueInString;
  fullLocation?: any;
  subMarketName?: string;
  subMarketId?: string;
  media: PhotoInterface[];
  floorPlans: UploadedMediaInterface[];
  yearBuilt: BuildingValueInNumber;
  yearRenovated: BuildingValueInNumber;
  floorsCount: BuildingValueInNumber;
  buildingSize: BuildingValueInNumber;
  averageFloor: BuildingValueInNumber;
  parkingRatio?: BuildingValueInString;
  columnSpacingHeight?: BuildingValueInNumber;
  columnSpacingWidth?: BuildingValueInNumber;
  slabToSlabHeight?: BuildingValueInString;
  passengerElevators: BuildingValueInString;
  freightElevators?: BuildingValueInString;
  telecomProviders?: BuildingValueInString;
  buildingHours: BuildingValueInString[];
  energyStarCertification?: BuildingValueInString;
  leedCertification?: BuildingValueInString;
  buildingAmenities?: BuildingValueInString[];
  buildingAmenitiesMatterportLinks?: AmenityMatterPortLink[];
  company: string;
  users: string[];
  website: BuildingValueInString;
  other: BuildingValueInString;
  plan: string;
}

export interface GetBuildingsListResponseInterface {
  _id: string;
  location: string;
}

export interface GetAllBuildingsResponseInterface {
  data: GetAllBuildingsDataInterface[];
  hasMore: boolean;
  skip: number;
}

export interface GetAllBuildingsSingleBuildingInterface {
  _id: string;
  media: PhotoInterface[];
  location: string;
  fullLocation: any;
  updatedAt: string;
  totalListings: number;
  buildingSize: BuildingValueInNumber;
  template?: string;
}

export interface GetAllBuildingsDataInterface {
  _id: string;
  market: string;
  subMarket: string;
  buildings: Array<GetAllBuildingsSingleBuildingInterface>;
}

export interface BuildingStateInterface {
  allBuildings: GetStateInterface<GetAllBuildingsResponseInterface>;
  singleBuilding: SingleBuildingDataInterface | null;
}

export enum BuildingActions {
  GET_ALL_BUILDINGS = 'GET_ALL_BUILDING',
  GET_ALL_BUILDINGS_CLEAR = 'GET_ALL_BUILDING_CLEAR',
  GET_ALL_BUILDINGS_INIT = 'GET_ALL_BUILDING_INIT',
  GET_ALL_BUILDINGS_SUCCESSFUL = 'GET_ALL_BUILDING_SUCCESSFUL',
  GET_ALL_BUILDINGS_FAILURE = 'GET_ALL_BUILDING_FAILURE',
  ADD_CUSTOM_DOMAIN = 'ADD_CUSTOM_DOMAIN',
  ADD_BUILDING = 'ADD_BUILDING',
  DELETE_BUILDING = 'DELETE_BUILDING',
  UPDATE_SINGLE_BUILDING = 'UPDATE_SINGLE_BUILDING',
  GET_SINGLE_BUILDING = 'GET_SINGLE_BUILDING',
  GET_SINGLE_BUILDING_BROKERS = 'GET_SINGLE_BUILDING_BROKERS',
  GET_SINGLE_BUILDING_WALK_SCORES = 'GET_SINGLE_BUILDING_WALK_SCORES',
  REMOVE_BUILDIG_BROKERS_FROM_PLAN = 'REMOVE_BUILDIG_BROKERS_FROM_PLAN',
}

export interface LocationMap {
  location?: string;
  longitude?: string;
  latitude?: string;
  fullLocation: any;
}

export interface GetAllBuildingsQueryInterface {
  companyId: string;
  subMarketId?: string;
  searchTerm?: string;
  limit?: number;
  skip?: number;
  listingStatus?: LISTING_STATUS;
  buildingStatus?: BUILDING_TEMPLATE_STATUS;
}

export interface UpdateSingleBuildingDataInterface {
  _id?: string;
  buildingHours?: BuildingValueInString[];
  buildingAmenities?: BuildingValueInString[];
  listings?: string[];
  users?: string[];
  location?: string;
  longitude?: BuildingValueInString;
  latitude?: BuildingValueInString;
  fullLocation?: FullLocation;
  subMarket?: string;
  yearBuilt?: BuildingValueInNumber;
  yearRenovated?: BuildingValueInNumber;
  floorsCount?: BuildingValueInNumber;
  buildingSize?: BuildingValueInNumber;
  averageFloor?: BuildingValueInNumber;
  parkingRatio?: BuildingValueInString;
  columnSpacingHeight?: BuildingValueInNumber;
  columnSpacingWidth?: BuildingValueInNumber;
  passengerElevators?: BuildingValueInString;
  freightElevators?: BuildingValueInString;
  telecomProviders?: BuildingValueInString;
  leedCertification?: BuildingValueInString;
  energyStarCertification?: BuildingValueInString;
  company?: string;
  buildingAmenitiesMatterportLinks?: UploadedMediaInterface[];
  other?: BuildingValueInString;
  slabToSlabHeight?: BuildingValueInString;
  website?: BuildingValueInString;
  media?: PhotoInterface[];
  matterPortLinks?: PhotoInterface[];
  floorPlans?: UploadedMediaInterface[];
  publishedTemplate?: string;
  selectedTemplate?: string;
  publishedAboutTemplateDescription?: string;
  selectedAboutTemplateDescription?: string;
  templateStatus?: string;
}
export interface SingleBuildingDataInterface {
  _id: string;
  buildingHours: BuildingValueInString[];
  buildingAmenities: BuildingValueInString[];
  isActive: boolean;
  listings: string[];
  users: string[];
  location: string;
  longitude: BuildingValueInString;
  latitude: BuildingValueInString;
  fullLocation: FullLocation;
  subMarket: string | SubMarketInterface;
  yearBuilt: BuildingValueInNumber;
  yearRenovated: BuildingValueInNumber;
  floorsCount: BuildingValueInNumber;
  buildingSize: BuildingValueInNumber;
  averageFloor: BuildingValueInNumber;
  parkingRatio: BuildingValueInString;
  columnSpacingHeight: BuildingValueInNumber;
  columnSpacingWidth: BuildingValueInNumber;
  passengerElevators: BuildingValueInString;
  freightElevators: BuildingValueInString;
  telecomProviders: BuildingValueInString;
  leedCertification: BuildingValueInString;
  energyStarCertification: BuildingValueInString;
  company: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
  buildingAmenitiesMatterportLinks: UploadedMediaInterface[];
  other: BuildingValueInString;
  slabToSlabHeight: BuildingValueInString;
  website: BuildingValueInString;
  media: PhotoInterface[];
  floorPlans?: UploadedMediaInterface[];
  publishedTemplate?: string;
  selectedTemplate?: string;
  publishedAboutTemplateDescription?: string;
  selectedAboutTemplateDescription?: string;
  templateStatus?: string;
  roomId?: string;
  plan?: string;
}

export interface BuildingTemplateContextInterface {
  subdomain: boolean;
  template: string;
  setTemplate: React.Dispatch<React.SetStateAction<string>>;
  showRightSidebar: boolean;
  setShowRightSidebar: React.Dispatch<React.SetStateAction<boolean>>;
  mobile: boolean;
  setMobile: React.Dispatch<React.SetStateAction<boolean>>;
  building: SingleBuildingDataInterface | null;
  edit: boolean;
  setEdit: React.Dispatch<React.SetStateAction<boolean>>;
  dirty: boolean;
  setDirty: React.Dispatch<React.SetStateAction<boolean>>;
  saveChanges: boolean;
  setSaveChanges: React.Dispatch<React.SetStateAction<boolean>>;
  saving: boolean;
  setSaving: React.Dispatch<React.SetStateAction<boolean>>;
  showForm: boolean;
  setShowForm: React.Dispatch<React.SetStateAction<boolean>>;
  basicInformationRef: any;
  aboutUsRef: any;
  brokersRef: any;
  listingsRef: any;
  specsRef: any;
  sustainabilityRef: any;
  listings: boolean;
  setListings: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface GetBuildingWalkScoresResponseInterface {
  walkScore?: number;
  transitScore?: number;
  bikeScore?: number;
}

export interface SingleListingSearchContextInterface {
  search: string | null;
  setSearch: React.Dispatch<React.SetStateAction<string | null>>;
}

export interface AddCustomDomainRequestInterface {
  customDomain: string;
  buildingId: string;
}
