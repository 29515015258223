import fetchActions from '../../../utils/state.utils';
import { CompanyActions } from './company.types';
import { getCompanyInfoAPI } from './company.api';
import axios from 'axios';

export const getCompanyInfo = async (subdomain: string) => {
  const actionName = CompanyActions.GET_COMPANY_INFO;
  fetchActions.init(actionName);
  fetchActions.callCT(actionName);
  try {
    const res = await getCompanyInfoAPI(subdomain, fetchActions.setCT(actionName));
    fetchActions.success(actionName, res.data);
  } catch (error) {
    if (!axios.isCancel(error)) {
      fetchActions.failure(actionName, error);
    }
  }
};
