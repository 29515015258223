import { GetStateInterface } from '../../types/GetStateInterface';
import { UploadedMediaInterface } from '../media/media.types';
import { MenuInterface } from '../subdomain/company/company.types';

export interface MeStateInterface {
  me: GetStateInterface<MeResponseInterface>;
}

interface Company {
  _id: string;
  name: string;
  domain: string;
  settings: {
    whiteLogo?: UploadedMediaInterface;
    darkLogo?: UploadedMediaInterface;
    whiteIcon?: UploadedMediaInterface;
    darkIcon?: UploadedMediaInterface;
    emailLogo?: UploadedMediaInterface;
    primaryColor?: string;
    secondaryColor?: string;
    officialAddress?: string;
    officialPhone?: string;
    officialWebsite?: string;
    termsUrl?: string;
    careersUrl?: string;
    privacyUrl?: string;
    menu?: MenuInterface[];
    contactEmail?: string;
    adminBackGroundImageUrl?: UploadedMediaInterface;
    suddomainLandingImageUrl?: UploadedMediaInterface;
    loaderColor?: string;
  };
}

export interface MeResponseInterface {
  _id: string;
  avatar: string;
  isActive: string;
  isEmailVerified: string;
  email: string;
  role: string;
  createdAt: string;
  updatedAt: string;
  company?: Company;
  firstName: string;
  lastName: string;
  activeBroker: boolean;
}

export enum MeActions {
  ME = 'ME',
  ME_INIT = 'ME_INIT',
  ME_SUCCESSFUL = 'ME_SUCCESSFUL',
  ME_FAILURE = 'ME_FAILURE',
  ME_UPDATE = 'ME_UPDATE',
}
