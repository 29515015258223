import { GetStateInterface } from '../../../types/GetStateInterface';
import { UploadedMediaInterface } from '../../media/media.types';

export interface SubSubSubMenuInterface {
  _id?: string;
  name: string;
  url: string;
}

export interface SubSubMenuInterface {
  _id?: string;
  name: string;
  url: string;
  subMenu?: SubSubSubMenuInterface[];
}
export interface SubMenuInterface {
  _id?: string;
  name: string;
  url: string;
  subMenu?: SubSubMenuInterface[];
}
export interface MenuInterface {
  _id?: string;
  name: string;
  url: string;
  subMenu?: SubMenuInterface[];
}

export interface CompanySettingsInterface {
  whiteLogo?: UploadedMediaInterface;
  darkLogo?: UploadedMediaInterface;
  whiteIcon?: UploadedMediaInterface;
  darkIcon?: UploadedMediaInterface;
  emailLogo?: UploadedMediaInterface;
  primaryColor?: string;
  secondaryColor?: string;
  officialAddress?: string;
  officialPhone?: string;
  officialWebsite?: string;
  termsUrl?: string;
  careersUrl?: string;
  privacyUrl?: string;
  menu?: MenuInterface[];
  contactEmail?: string;
}

export interface CompanyInfoInterface {
  _id: string;
  name: string;
  settings: CompanySettingsInterface;
  loggedIn: boolean;
}

export interface CompanySubDomainStateInterface {
  companyInfo: GetStateInterface<CompanyInfoInterface>;
}

export enum CompanyActions {
  GET_COMPANY_INFO = 'GET_COMPANY_INFO',
  GET_COMPANY_INFO_INIT = 'GET_COMPANY_INFO_INIT',
  GET_COMPANY_INFO_SUCCESSFUL = 'GET_COMPANY_INFO_SUCCESSFUL',
  GET_COMPANY_INFO_FAILURE = 'GET_COMPANY_INFO_FAILURE',
}
